import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import amplitude from "amplitude-js";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { getPreviousResignedSearchStatus, getSiteValueByLang, setNewDesignStatusLocally } from "../utils/SiteUtils";
export const setupExternalServices = () => {
    setAmplitude(null);
    setGoogleAnalytics();
    setBookingDotComWidget();
};
export const setupExternalServicesWithUser = (user) => {
    const { id: userId, email: userEmail, name: userName, phone: userPhone } = user.user;
    setAmplitude(userId);
    setTagManager(userId, userEmail, userName, userPhone);
    setDataDog(userId, userEmail);
};
export const setAmplitude = (userId) => {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
        defaultTracking: true,
        sameSiteCookie: 'None',
        secureCookie: true,
        includeUtm: true,
        includeReferrer: true,
        includeGclid: true,
        includeFbclid: true
    });
    amplitude.getInstance().setUserId(userId)
};
export const setGoogleAnalytics = () => {
    const gaMeasurementId = getSiteValueByLang("gaMeasuremntId");
    ReactGA.initialize(gaMeasurementId);
};
export const setDataDog = (userId, userEmail) => {
    datadogLogs.init({
        clientToken: 'pubfe3de757580b6543d9d5412bb7f0fb22',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['log', 'error'],
        sessionSampleRate: 100,
        service: 'plt-web',
        env: process.env.REACT_APP_ENVIRONMENT
    })

    datadogRum.init({
        applicationId: "1e8e61a0-eada-4ad5-a6ae-8dc5ebfa8fed",
        clientToken: "pubfe3de757580b6543d9d5412bb7f0fb22",
        site: "datadoghq.com",
        service: "plt-web",
        env: process.env.REACT_APP_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
    });

    datadogRum.setUser({
        id: userId,
        email: userEmail
    });

    datadogRum.startSessionReplayRecording();
};
export const setTagManager = (userId, userEmail, userName, userPhone) => {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
        auth: process.env.REACT_APP_GTM_ENV_AUTH,
        preview: process.env.REACT_APP_GTM_ENV_PREVIEW,
        dataLayer: {
            user: {
                id: userId,
                email: userEmail,
                name: userName,
                phone: userPhone
            },
            nodeEnv: process.env.NODE_ENV
        }
    };
    TagManager.initialize(tagManagerArgs);
};
export const setBookingDotComWidget = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `https://aff.bstatic.com/static/affiliate_base/js/flexiproduct.js?v=${+new Date()}`;
    const p = document.getElementsByTagName("script")[0];
    p.parentNode.insertBefore(script, p);
};
export const shouldDisplayTransparentHeader = (path) => {
    if (["/", "/flights", "/stays"].includes(path)) {
        return true;
    }
    if (path.match(/\/stays\/[^.]*\/[^.]*/)) {
        return true;
    }
    return false;
};

export const randomizeDisplayForRedesignedSearch = (setDisplayRedesignedSearch) => {
    const isNewDesignStatus = getPreviousResignedSearchStatus();
    const probability = Math.floor(Math.random() * 100);
    if (isNewDesignStatus !== null) {
        setDisplayRedesignedSearch(isNewDesignStatus === "true");
        return;
    }

    if (probability <= 100) {
        setNewDesignStatusLocally(true);
        setDisplayRedesignedSearch(true);
        return;
    }
    setNewDesignStatusLocally(false);
    setDisplayRedesignedSearch(false);
};
